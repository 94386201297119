// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coaching-form-js": () => import("./../../../src/pages/coachingForm.js" /* webpackChunkName: "component---src-pages-coaching-form-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-training-form-js": () => import("./../../../src/pages/trainingForm.js" /* webpackChunkName: "component---src-pages-training-form-js" */),
  "component---src-pages-trainings-js": () => import("./../../../src/pages/trainings.js" /* webpackChunkName: "component---src-pages-trainings-js" */)
}

